<!-- 获取文章列表界面 -->
<template>
    <div>
        <div class="articleListNavi">
            <!-- 面包屑导航 -->
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{path: '/welcome' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>文章管理</el-breadcrumb-item>
                <el-breadcrumb-item>文章</el-breadcrumb-item>
            </el-breadcrumb>
        </div>


        <!-- 卡片视图区域  -->
        <el-card class="box-card">
            <!-- gutter设置el-col之间的间距 -->
            <el-row :gutter="20">
                <!-- 添加按钮 :span="3"-->
                <el-col :span="3">
                    <el-button type="primary" @click='addArticleInformation'>
                        新建文章
                    </el-button>
                </el-col>

                <el-select class="articleTypeStyle" v-model="articleType" placeholder="请选择文章分类">
                    <el-option key="999" value="全部">
                    </el-option>

                    <el-option v-for="item in articleTypeList" :key="item.typeId" :value="handleArticleType(item)">
                    </el-option>
                </el-select>

                <el-button class="searchArticleTypeStyle" type="primary" @click='searchArticleInformation'>
                    搜索
                </el-button>

            </el-row>

            <!-- 标签列表  ：data绑定数据源，prop指定参数对应的值， border边框，stripe隔行变色-->
            <el-table :data="articleList" border stripe style="width: 100%"
                :header-cell-style="{'text-align':'center'}">
                <el-table-column prop="articleId" label="文章id" width="70" align="center">
                </el-table-column>
                <el-table-column prop="articleTitle" label="文章标题" width="220" align="center">
                </el-table-column>
                <el-table-column prop="articleType" label="文章分类" width="80" align="center">
                </el-table-column>
                <el-table-column prop="articleTag" label="文章标签" width="200" align="center">
                </el-table-column>
                <el-table-column prop="articleViews" label="阅读次数" width="80" align="center">
                </el-table-column>
                <el-table-column prop="articleGrade" label="等级" width="50" align="center">
                </el-table-column>
                <el-table-column prop="createdTime" label="创建时间" width="140" align="center">
                </el-table-column>
                <el-table-column label="是否删除" width="80">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.articleDeleted" @change="userStateChanged(scope.row)">
                        </el-switch>
                    </template>
                </el-table-column>

                <el-table-column label="是否登录" width="80">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.articleLogin" @change="articleLoginChanged(scope.row)">
                        </el-switch>
                    </template>
                </el-table-column>


                <el-table-column label="操作">
                    <!-- 作用域插槽的使用 -->
                    <template slot-scope="scope">
                        <!-- enterablez 鼠标是否可进入到 tooltip 中 自动隐藏-->
                        <el-tooltip effect="dark" content="编辑" placement="top" :enterable="false">
                            <!-- 编辑按钮 -->
                            <el-button type="primary" icon="el-icon-edit" @click="showEditDialog(scope.row)">
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 页码 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="tagInfor.page" :page-size="articlePageSize" layout="total, prev, pager, next, jumper"
                :total="articleTotal" class="articlePage-list">
            </el-pagination>

        </el-card>

    </div>
</template>



<!-- 行为 -->
<script>
    export default {

        // 数据处理
        data() {
            return {
                //获取文章列表的所需要设置的参数
                tagInfor: {
                    page: 1, //文章标签列表页码数，默认每页5条数据
                },

                articlePage: 1,  //文章页码数，默认每页5条数据
                articleState: 0,  //状态码，0代表按照日期排序，1代表
                articleTotal: 0,  //文章总数
                articlePageSize: 5, //默认每页5条数据

                //文章列表---每页最多5条数据
                articleList: [],
                //添加文章标签的表单数据对象
                addForm: {
                    tagName: '',    //标签名称
                },

                articleType: '',        //文章分类
                articleTypeId: 0,        //文章分类ID
                articleTypeList: [],    //文章分类列表
                articleTagList: [],     //文章标签列表

                // 添加文章标签表单数据的验证规则对象  trigger:blur对应必填项，change为选填项
                addFormRules: {
                    tagName: [
                        { required: true, message: '请输入标签名称', trigger: 'blur' },
                        { min: 2, max: 15, message: '标签名称长度在2到15个字符', trigger: 'blur' }
                    ],
                },

                //获取到修改标签的数据对象
                editForm: {},

                // 修改标签名称表单数据的验证规则对象  trigger:blur对应必填项，change为选填项
                editFormRules: {
                    tagName: [
                        { required: true, message: '请输入标签名称', trigger: 'blur' },
                        { min: 2, max: 15, message: '标签名称长度在2到15个字符', trigger: 'blur' }
                    ]
                }
            }
        },

        //创建
        created() {
            this.getArticleList();
            this.getArticleTypeList();
        },

        // 方法
        methods: {
            // 获取文章标签列表, async代表异步操作
            async getArticleList() {
                console.log('获取文章列表-------');
                const { data: res } = await this.$http.get('/article/article/list', {
                    params: {
                        page: this.tagInfor.page,
                        state: this.articleState,
                        articleTypeId: this.articleTypeId,
                        token: window.sessionStorage.getItem('token'),
                    },
                });
                if (res.status >= 400 && res.status <= 410) {//用户权限问题，需要登录
                    this.$router.push('/login');
                    return this.$message.error(res.msg);
                } else {
                    if (res.status != 200) {
                        return this.$message.error(res.msg);
                    }
                }
                this.articleList = res.articleList;
                this.articleTotal = res.totalNumber;
            },

            // 监听pageSize改变事件
            handleSizeChange(newPage) {
                this.tagInfor.page = newPage;
                this.getArticleList();
            },

            // 监听当前页码改变
            handleCurrentChange(newPage) {
                this.tagInfor.page = newPage;
                this.getArticleList();
            },

            // 获取文章分类列表, async代表异步操作
            async getArticleTypeList() {
                const { data: res } = await this.$http.get('/article/type/list', {
                    params: {
                        page: 999, //文章分类page传入999，代表获取全部文章标题列表
                    }
                });
                this.articleTypeList = res.typeList;
                // this.articleTypeParentList = res.typeParentList;
            },

            // 处理文章分类相关数据
            handleArticleType: function (item) {
                return item.parentName + "/" + item.subName;
            },

            // 新建文章
            addArticleInformation() {
                // 跳转到文章新建页面
                this.$router.push('/articleAdd');
            },

            // 查询文章
            searchArticleInformation() {
                console.log('articleType----', this.articleType);
                this.articlePage = 1;
                if (this.articleType == "全部") {
                    this.articleTypeId = 0;
                } else {
                    this.articleTypeList.forEach((typeDict) => {
                        if (typeDict.parentName + "/" + typeDict.subName == this.articleType) {
                            console.log('articleType----', typeDict.typeId);
                            this.articleTypeId = typeDict.typeId;
                        }
                    })
                }
                this.getArticleList();
            },

            //点击编辑的修改标签弹窗
            showEditDialog(row) {
                console.log(row.articleTitle);
                window.sessionStorage.setItem('articleId', row.articleId);
                window.sessionStorage.setItem('articleTitle', row.articleTitle);
                window.sessionStorage.setItem('articleIntroduction', row.articleIntroduction);
                window.sessionStorage.setItem('articleContent', row.articleContent);
                window.sessionStorage.setItem('articleType', row.articleType);
                window.sessionStorage.setItem('articleTypeDetail', row.articleTypeDetail);
                window.sessionStorage.setItem('articleTag', row.articleTag);
                window.sessionStorage.setItem('articleViews', row.articleViews);
                window.sessionStorage.setItem('articleGrade', row.articleGrade);
                // 跳转到文章新建页面
                this.$router.push('/articleEdit');
            },

            //修改标签弹窗关闭
            editArticleTagDialogClose() {
                // undefined 表示一个变量未定义或未赋值的初始状态值
                if (this.$refs.editFormRules !== undefined) {
                    this.editFormRules.resetFields();
                }
            },


            // 监听 switch 开关状态的改变
            async userStateChanged(articleInfo) {
                console.log('开关状态的改变-------')
                console.log(articleInfo.articleDeleted)
                var msgStr = '此操作将打开该文章显示,是否继续?'
                if (articleInfo.articleDeleted) {
                    msgStr = '此操作将关闭该文章显示, 是否继续?'
                }

                // 询问是否真的删除当前
                const confirmResult = await this.$confirm(msgStr, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch(err => {
                    return err;
                });

                // 如果标签名称，确认删除，则返回值为字符串 confirm 
                // 如果标签名称，取消删除，则返回值为字符串 cancel 
                console.log(confirmResult);
                if (confirmResult !== 'confirm') {
                    articleInfo.articleDeleted = !articleInfo.articleDeleted;
                    return this.$message.info('已取消当前操作');
                }

                //预校验通过,
                const { data: res } = await this.$http.post('/article/article/delete', {
                    articleId: articleInfo.articleId,
                    articleDeleted: articleInfo.articleDeleted,
                    token: window.sessionStorage.getItem('token'),
                });
                console.log(res.data);
                if (res.status !== 200) {
                    return this.$message.error(res.msg);
                }
                this.$message.success('设置成功');
            },


            // 监听 switch 开关状态的改变
            async articleLoginChanged(articleInfo) {
                console.log('开关状态的改变-------')
                console.log(articleInfo.articleLogin)
                var msgStr = '此操作将打开该文章需要登录才能查看详情,是否继续?'
                if (articleInfo.articleLogin) {
                    msgStr = '此操作将关闭该文章需要登录才能查看详情, 是否继续?'
                }

                // 询问是否真的删除当前
                const confirmResult = await this.$confirm(msgStr, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch(err => {
                    return err;
                });

                // 如果标签名称，确认删除，则返回值为字符串 confirm 
                // 如果标签名称，取消删除，则返回值为字符串 cancel 
                console.log(confirmResult);
                if (confirmResult !== 'confirm') {
                    articleInfo.articleLogin = !articleInfo.articleLogin;
                    return this.$message.info('已取消当前操作');
                }

                //预校验通过,
                const { data: res } = await this.$http.post('/article/article/isLogin', {
                    articleId: articleInfo.articleId,
                    articleLogin: articleInfo.articleLogin,
                    token: window.sessionStorage.getItem('token'),
                });
                console.log(res.data);
                if (res.status !== 200) {
                    return this.$message.error(res.msg);
                }
                this.$message.success('设置成功');
            },

        },

    }
</script>



<!-- 样式 -->
<style lang="less" scoped>
    /*  使用deep样式穿透 面包屑导航里面字体样式 */
    .articleListNavi /deep/ .el-breadcrumb {
        font-size: 20px !important;
    }

    /* 文章分类的样式 */
    .articleTypeStyle {
        margin-left: 10px;
        padding-left: 5px;
        width: 150px;
    }

    .searchArticleTypeStyle {
        margin-left: 10px;
        width: 80px;
    }
</style>