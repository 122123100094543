 /* jshint esversion: 6 */ 
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './plugins/element.js';
import 'element-ui/lib/theme-chalk/index.css';  //样式必须引入
// 导入全局样式表
import './assets/css/global.css';

//引入axios 进行网络请求相关
import axios from 'axios';

//md5加密
import md5 from "js-md5";

//配置请求的根路径 外网发布
// axios.defaults.baseURL = 'http://116.62.79.240/api';
axios.defaults.baseURL = 'https://panjunfei.com/api';


// 本地调试
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api';
axios.defaults.timeout = 3000; //设置超时时间

// 需要添加token的api
const exceptUrls = ['/user/list'];

// request 拦截器
axios.interceptors.request.use(config => {
  // X-AUTH-TOKEN
  const tokenStr = window.sessionStorage.getItem('token');
  // console.log('tokenStr的值');
  // console.log(tokenStr);

  // config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
  config.headers['Content-Type'] = 'application/json; charset=UTF-8';


  // console.log(config.baseURL);
  // console.log(config.url);
  // console.log(exceptUrls.indexOf(config.url));

  if (tokenStr && exceptUrls.indexOf(config.url) >= 0) {
    config.headers.Authorization = 'Token ' + tokenStr;
  }
  return config; 
});


//将axios挂载在Vue上面，这样Vue就直接访问
Vue.prototype.$http = axios;
Vue.config.productionTip = false;

//MD5加密 
Vue.prototype.$md5 = md5;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
