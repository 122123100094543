<template>
  <el-container class="home-container">
    <!-- 头部 -->
    <el-header class="home-header">
      <div class="home-header-title">
        <span>飞哥个人博客</span>
      </div>

      <div>
        <!-- text-color="#fff" active-text-color="#ffd04b"
          mode="horizontal" -->
        <el-menu router :default-active="activePath" class="el-menu-demo" text-color="#fff" active-text-color="#ffd04b"
          mode="horizontal">
          <template class="home-menu-item33" v-for="item in navigateItem">
            <el-submenu class="home-menu-item1" v-if="item.items.length" :index="item.key" :key="item.key">
              <template slot="title">
                {{ item.title }}
              </template>

              <el-menu-item class="home-menu-item2" v-for="items in item.items" :index="'/' + items.path"
                :key="items.key" @click="saveNaveState(items)">
                {{ items.title }}
              </el-menu-item>
            </el-submenu>

            <el-menu-item class="home-menu-item3" v-else :index="'/' + item.path" :key="item.key"
              @click="saveNaveState(item)">
              {{ item.title }}
            </el-menu-item>
          </template>
        </el-menu>
      </div>

      <!-- 右侧的个人信息 -->
      <div class="home-btn">
        <div class="home-btn-my">
          <!-- 个人信息 -->
          <span v-show="isLoginShow">{{this.userName}}</span>
          <div class="home-btn-information">
            <el-link type="primary" @click="exitBtn">退出</el-link>
          </div>
        </div>
        <el-button class="home-login-btn" type="primary" plain @click="loginBtn" v-show="!isLoginShow">登录</el-button>
        <el-button class="home-register-btn" type="primary" plain @click="registerBtn" v-show="!isLoginShow">注册</el-button>
      </div>
    </el-header>


    <!-- 中间  绑定中间位置高度随屏幕高德变化而变化-->
    <el-main class="home-main" :style="{height: windowHeight - 200 + 'px'}">
      <!-- 设置一个路由占位符 -->
      <router-view></router-view>

      <!-- 尾部 -->
      <div class="home-footer">
        <div class="home-footer-title">
          <div class="home-footer-icon">
            <ul>
              <li>
                <img src="../assets/images/weixin-gongzhonghao.png">
                <span>微信公众号</span>
              </li>
            </ul>
          </div>

          <div class="home-footer-content">
            <p><b>本站申明</b></p>
            <p>1、本站个人博客模板，均为飞哥本人设计，个人可以使用，但是未经许可不得用于任何商业目的。</p>
            <p>
              2、所有文章未经授权禁止转载、摘编、复制或建立镜像，如有违反，追究法律责任。举报邮箱：<a target="_blank">hbwhfg@qq.com</a>
            </p>
            <p>3、前端版本: 1.2.2.1,  后端版本:1.1.1，更新时间：2024年01月03日。</p>
            <p>4、备案号: <a href="https://beian.miit.gov.cn" target="_blank"> 鄂ICP备2021019819号-1</a>
            </p>
          </div>
        </div>
      </div>
    </el-main>

  </el-container>
</template>



<!-- 行为层 -->
<script>
  export default {
    // SEO化，动态设置, 
    metaInfo() {
      return {
        title: this.headTitle,
        meta: [{
          charset: "utf-8"
        },
        {
          name: 'keyWords',
          content: this.headKeyWord,
        },
        {
          name: 'description',
          content: this.headDescription,
        }]
      }
    },

    data() {
      return {
        windowWidth: document.documentElement.clientWidth,//实时屏幕宽度
        windowHeight: document.documentElement.clientHeight,//实时屏幕高德
        activePath: '/firstPage',  //默认菜单栏的地址
        userName: '',
        isLoginShow: true,//是否显示个人信息
        navigateItem: [],//导航栏----标题数据

        // SEO化
        headTitle: '飞哥个人博客',
        headKeyWord:'个人博客, 个人网站, python, Qt, iOS, 前端',        
        headDescription:'飞哥, 个人博客, 个人网站, python, Qt',
      }
    },


    // 在watch中监听实时宽高
    watch: {
      windowHeight(val) {
        let that = this;
        console.log("实时屏幕高度：", val, that.windowHeight);
      },

      windowWidth(val) {
        let that = this;
        console.log("实时屏幕宽度：", val, that.windowWidth);
      }
    },

    //created:在模板渲染成html前调用，即通常初始化某些属性值，然后再渲染成视图。
    created() {
      console.log('created------home22222222--');
      const tempActivePath = window.sessionStorage.getItem('activePath');
      console.log(tempActivePath);
      if (tempActivePath) {
        this.activePath = tempActivePath;
      } else {
        window.sessionStorage.setItem('activePath', '/firstPage');
      }
      // document.title = '飞哥个人博客';
      this.getInformatin();
      this.getArticleTypeNaviBar();
    },

    // 监听操作
    watch: {
      '$route'(to, from) {
        console.log('保存链接的激活状态------watch---home--');
        if (to['path'] == '/firstPage') {
          window.sessionStorage.setItem('activePath', to['path']);
          this.activePath = to['path'];
        }
      }
    },

    // 方法
    methods: {

      // 保存链接的激活状态
      saveNaveState(item) {
        window.sessionStorage.setItem('activePath', '/' + item.path);
        this.activePath = '/' + item.path;
        console.log('保存链接的激活状态------home-----');
        console.log(this.activePath);
        console.log(item.title);

        window.sessionStorage.setItem('typeTitle', item.title);
        window.sessionStorage.setItem('typeContent', item.content);
        window.sessionStorage.setItem('articleTypeId', item.key);

        window.sessionStorage.setItem('li_style', 'li_select');
        window.sessionStorage.setItem('li_style2', 'li_normarl');
        window.sessionStorage.setItem('articleState', 0);
        window.sessionStorage.setItem('articlePage', 1);
      },

      // 获取个人信息
      getInformatin() {
        const userNameStr = window.sessionStorage.getItem('userName');
        console.log('获取个人信息-------',userNameStr)
        if (userNameStr) {
          this.userName = userNameStr;
          this.isLoginShow = true;
        } else {
          this.isLoginShow = false;
          this.userName = '';
        }
      },

      //进入登录界面
      loginBtn() {
        this.$router.push('/login');
      },

      //进入注册界面
      registerBtn() {
        this.$router.push('/register');
      },

      exitBtn() {
        window.sessionStorage.removeItem('token');
        location.reload();
      },

      // 获取菜单栏NaviBar, async代表异步操作
      async getArticleTypeNaviBar() {
        const { data: res } = await this.$http.get('/article/type/naviBar', {
          params: {
            username: window.sessionStorage.getItem('userEmail'),     //用户邮箱
            token: window.sessionStorage.getItem('token'),     //用户邮箱
          }
        });
        console.log(res);
        this.navigateItem = res.typeList;
      },

    },


    // 事件、函数
    mounted() {
      var that = this;
      // 把window.onresize事件挂在到mounted函数上
      console.log('mounted-----事件、函数--')
      window.onresize = () => {
        return (() => {
          window.fullHeight = document.documentElement.clientHeight;
          window.fullWidth = document.documentElement.clientWidth;
          that.windowHeight = window.fullHeight;  // 高
          that.windowWidth = window.fullWidth; // 宽
        })()
      };
    },

  }
</script>



<style>
  /* 导航菜单对应的下拉二级菜单的背景区域 */
  .el-menu--popup {
    background-color: #151a1d !important;
    min-width: 120px !important;
  }
</style>

<!-- 样式 -->
<!-- scoped表示当前页面有效    lang="less" scoped  -->
<style lang="less" scoped>
  .div {
    padding: 0px;
    margin: 0px;
  }

  .home-container {
    height: 100%;
  }

  /* 头部菜单栏---所在大区域 */
  .home-header {
    /*  背景颜色 */
    background-color: #151a1d;
    display: flex;
    /* 二端对齐方式 */
    justify-content: space-between;
    padding-left: 0px;
    /* 退出按钮居中显示 */
    align-items: center;
    /* 字体颜色 */
    color: #ffffff;
  }

  /* 网站标题 ---最左边区域*/
  .home-header-title {
    display: flex;
    align-items: center;
    padding-left: 10px;
    height: 30px;
    font-size: 20px;
    font-weight: bold;
  }

  /* 中间导航栏---所在区域 */
  .el-menu-demo {
    background-color: #151a1d;
    width: 600px;
    /* 布局 */
    /* display: flex; */
    display: contents;
    /* 左间距 */
    padding-left: 5px;
  }

  /* 首页 菜单栏样式 */
  .home-menu-item3 {
    background-color: #151a1d !important;
    width: 60px !important;
    padding-left: 10px;
    font-size: 20px !important;
  }

  /* 一级菜单栏所在区域 */
  .el-submenu {
    background-color: #151a1d !important;
    padding-left: 0px;
    /* 文本对齐方式 */
    justify-content: center;
    width: 100px;
    height: 40px;
    color: white;
  }

  /* 二级菜单栏样式 */
  .home-menu-item2 {
    font-size: 18px !important;
  }

  /* 一级菜单栏样式(如：技术、生活、更多等都是一级菜单栏，而首页只有一级菜单栏，没有二级菜单栏) */
  .el-menu-demo /deep/ div.el-submenu__title {
    font-size: 20px;
  }

  /* 一级菜单栏样式(如：技术、生活、更多等都是一级菜单栏，而首页只有一级菜单栏，没有二级菜单栏)  悬停状态*/
  .el-menu-demo /deep/ div.el-submenu__title:hover {
    background-color: #151a1d !important;
  }

  /* 一级菜单栏样式(如：技术、生活、更多等都是一级菜单栏，而首页只有一级菜单栏，没有二级菜单栏)，被选中的情况 */
  .el-menu-demo /deep/ .el-submenu.is-active .el-submenu__title {
    /* 字体颜色  87CEEB     00c1de*/
    color: #00c1de !important;
    border-bottom: #00c1de 3px solid !important;
    border-left: 10px;
  }

  /* 一级或者二级菜单栏选中样式 */
  .el-menu-item.is-active {
    color: #00c1de !important;
    border-bottom: #00c1de 3px solid !important;
    border-left: 10px;
  }

  /* 一级菜单栏默认样式 */
  .el-menu-item {
    background-color: #151a1d !important;
    font-size: 18px;
  }

  /* 一级或者二级菜单栏 悬停对应样式 */
  .el-menu-item:hover {
    color: #00c1de !important;
  }

  /* 右侧的个人信息 */
  .home-btn {
    margin-right: 60px;
  }

  .home-btn-my {
    font-size: 24px;
  }

  /* 在用户名称上面悬停的时候样式 */
  .home-btn-my span:hover {
    color: #00c1de;
  }

  /* 在个人信息上面悬停，显示 */
  .home-btn-my:hover .home-btn-information {
    display: block;
    color: white !important;
  }

  /* 个人信息---悬停对应下拉框样式 */
  .home-btn-information {
    background-color: #151a1d;
    color: white !important;
    width: 80px;
    height: 90px;
    margin-right: 100px;
    /* 父元素开启绝对定位 */
    position: absolute;
    top: 40px;
    /* none代表代表隐藏 */
    display: none;
    font-size: 30px;
  }

  .home-btn-information:hover {
    display: block;
    color: white !important;
  }

  .home-btn-information el-link {
    padding: 5px;
    height: 30px;
    width: 100%;
    /* 使el-link成为块级元素，此元素前后会带换行符 ---效果上下排列*/
    display: block;
    font-size: 30px;
  }

  .home-btn-information el-link:hover {
    /* 底部下划线 */
    border-bottom: #00c1de 2px solid;
  }

  /* 登录操作的样式 */
  .home-login-btn {
    width: 60px;
    font-size: 20px !important;
    /* 文字在按钮里面距离top、right、bottom、left的距离 */
    padding: 5px 5px !important;
  }

  /* 注册操作的样式 */
  .home-register-btn {
    width: 60px;
    font-size: 20px !important;
    padding: 5px 5px !important;
  }

  /* 设置登录样式 */
  .el-link.el-link--primary {
    font-size: 24px;
  }


  /* 中间部分样式 */
  .home-main {
    /*  背景颜色 */
    background-color: #eaeaea;
    margin: 0px;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  /* 底部 */
  .home-footer-title {
    /*  背景颜色 */
    background-color: #373D41;
    border-top: #00C1DE 10px solid;
    display: flex;
    /* 文本对齐方式 */
    justify-content: center;
    padding-left: 0px;
    /* 退出按钮居中显示 */
    align-items: center;
    /* 字体颜色 */
    color: #ffffff;
    font-size: 14px;
    height: 150px;
  }

  .home-footer-icon {
    overflow: hidden;
    float: left;
    margin-left: 20px;
    display: block;
    list-style: none;
  }

  .home-footer-icon li {
    float: left;
    margin: 20px;
    text-align: center;
    display: list-item;
  }

  .home-footer-icon img {
    height: 100px;
    width: 100px;
    display: block;
  }


  .home-footer-content {
    margin: 0px;
    padding: 0px;
  }


  /* 本站申明 */
  .home-footer-content b {
    color: #00C1DE !important;
    font-size: 20px;
    margin-top: 10px;
    padding-top: 10px;
  }


  .home-footer-content p {
    font-size: 14px;
    height: 20px;
    margin: 5px 5px;
    /* padding: 0px; */
  }

  .home-footer-content a {
    color: #ffffff;
    font-size: 14px;
  }
</style>